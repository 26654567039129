import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { AppConsts } from '@shared/AppConsts';
import { MutService } from './mut.service';

@Injectable()
export class MutStepGuard implements CanActivate {
    constructor(private mutService: MutService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // Navigate to first step
        const caseId = this.mutService.caseId;
        const step = this.mutService.getUrlForStep(this.mutService.firstStep);

        let urlBase = '';
        if (state.url.indexOf(AppConsts.authorityStartUrl) >= 0) {
            urlBase = AppConsts.authorityStartUrl;
        } else {
            urlBase = AppConsts.applicantBaseUrl;
        }

        const url = `${urlBase}/mut/${caseId}/wizard/${step}`;
        this.router.navigate([url]);

        return of(true);
    }
}
