import { AppConsts } from '@shared/AppConsts';
import { FilterMetadata, LazyLoadEvent } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { DateTime } from 'luxon';
import * as rtlDetect from 'rtl-detect';

export class PrimengTableHelper {
    predefinedRecordsCountPerPage = [ 10, 20, 50, 100, 250, 500];

    defaultRecordsCountPerPage = 20;

    isResponsive = true;

    resizableColumns: false;

    totalRecordsCount = 0;

    records: any[];

    selectedRecords: any[];

    isLoading = false;

    filter = {};

    showLoadingIndicator(): void {
        setTimeout(() => {
            this.isLoading = true;
        }, 0);
    }

    hideLoadingIndicator(): void {
        setTimeout(() => {
            this.isLoading = false;
        }, 0);
    }

    getSorting(table: Table): string {
        let sorting = '';

        if (table.sortMode === 'multiple') {
            if (table.multiSortMeta) {
                for (let i = 0; i < table.multiSortMeta.length; i++) {
                    const element = table.multiSortMeta[i];
                    if (i > 0) {
                        sorting += ',';
                    }
                    sorting += element.field;
                    if (element.order === 1) {
                        sorting += ' ASC';
                    } else if (element.order === -1) {
                        sorting += ' DESC';
                    }
                }
            }
        } else {
            if (table.sortField) {
                sorting = table.sortField;
                if (table.sortOrder === 1) {
                    sorting += ' ASC';
                } else if (table.sortOrder === -1) {
                    sorting += ' DESC';
                }
            }

        }

        return sorting;
    }

    getSortField(event: LazyLoadEvent): string {
        if (!event.sortField) {
            return '';
        }

        let sorting = event.sortField;

        if (event.sortOrder === 1) {
            sorting += ' ASC';
        } else if (event.sortOrder === -1) {
            sorting += ' DESC';
        }

        return sorting;
    }

    getFiltering(table: Table): string {
        return this.getFilterInternal(table.filters);
    }

    getFilter(event: LazyLoadEvent): string {
        return this.getFilterInternal(event.filters);
    }

    getFilterInternal(filters: FilterMetadata): string {

        const toDynamicLinq = (key, matchMode, value) => {
            const userTimeZone = abp.timing.timeZoneInfo.iana.timeZoneId;
            const valueAsDate = DateTime.fromJSDate(value, { zone: userTimeZone }).toUTC();
            const parseAsDate = (key.startsWith('date') || key.endsWith('Date')) && valueAsDate.isValid && matchMode !== 'dateOnlyEq';
            const parseAsDateOnly = valueAsDate.isValid && matchMode === 'dateOnlyEq';
            const parseAsNumber = !isNaN(+value) && ['is', 'lt', 'lte', 'gt', 'gte'].includes(matchMode);
            const isArray = Array.isArray(value);
            const isNumericArray = isArray && value.every(x => !isNaN(+x));
            const isDateArray = isArray && value.every(x => DateTime.fromJSDate(x, { zone: userTimeZone }).toUTC().isValid);
            let valueEndRange: any;

            if (isArray === true) {
                if (isDateArray) {
                    value = value
                        .map(x => DateTime.fromJSDate(x, { zone: userTimeZone }).toUTC())
                        .map(x => `DateTime(${x.toFormat('yyyy,LL,dd,HH,mm,ss')})`);
                }
                else if (isNumericArray) {
                    value = value.join(',');
                } else {
                    value = value.map(x => '"' + x + '"').join(',');
                }
            } else {
                if (parseAsDate) {
                    value = `DateTime(${valueAsDate.toFormat('yyyy,LL,dd,HH,mm,ss')})`;
                    valueEndRange = `DateTime(${valueAsDate.plus({ days: 1 }).toFormat('yyyy,LL,dd,HH,mm,ss')})`;
                } else if (parseAsDateOnly) {
                    value = `"${DateTime.fromJSDate(value).toFormat('yyyy,LL,dd')}"`;
                } else if (!parseAsNumber) {
                    value = '"' + value + '"';
                }
            }

            switch (matchMode) {
                case 'in': return `(${key} in (${value}))`;
                case 'contains': return `${key}.Contains(${value})`;
                case 'startsWith': return `${key}.StartsWith(${value})`;
                case 'endsWith': return `${key}.EndsWith(${value})`;
                case 'lt': return `${key}<${value}`;
                case 'lte': return `${key}<=${value}`;
                case 'gt': return `${key}>${value}`;
                case 'gte': return `${key}>=${value}`;
                case 'range': return `${key}>=${value[0]} and  ${key}<=${value[1]}`;
                case 'is': /* used for numeric equality, see parseAsNumber */
                case 'dateOnlyEq':
                case 'equals':
                default: return !parseAsDate ? `${key}=${value}` : `${key}>=${value} and ${key}<${valueEndRange}`;
            }
        };

        const filter = Object.entries(filters).map(([k, v]) => toDynamicLinq(k, v['matchMode'], v['value'])).join(' and ');
        return filter;
    }

    filterDateIfValid(table: Table, textValue: string, model: Date, column: string, matchMode: string) {
        if (textValue && !model) {
            return;
        }

        table.filter(model, column, matchMode);
    }

    hasFilter(table: Table): boolean {
        return Object.keys(table.filters).length > 0;
    }

    clearFilter(table: Table): void {
        this.filter = {};
        table.filters = {};
        table.filter(null, null, null);
    }

    getFilerValue(table: Table, key: string): string {
        let filter: FilterMetadata | FilterMetadata[] = table.filters[key];

        if (!Array.isArray(filter)) {
            return filter?.value ?? '';
        }

        return filter.length > 0 ? filter[0]?.value : '';
    }

    getMaxResultCount(paginator: Paginator, event: LazyLoadEvent): number {
        if (paginator && paginator.rows) {
            return paginator.rows;
        }

        if (!event || !event.rows) {
            return this.defaultRecordsCountPerPage;
        }

        return event.rows;
    }

    getSkipCount(paginator: Paginator, event: LazyLoadEvent): number {
        if (paginator && paginator.first) {
            return paginator.first;
        }

        if (!event || !event.first) {
            return 0;
        }

        return event.first;
    }

    shouldResetPaging(event: LazyLoadEvent): boolean {
        if (!event /*|| event.sortField*/) { // if you want to reset after sorting, comment out parameter
            return true;
        }

        return false;
    }

    adjustScroll(table: Table) {
        const rtl = rtlDetect.isRtlLang(abp.localization.currentLanguage.name);
        if (!rtl) {
            return;
        }

        const body: HTMLElement = table.el.nativeElement.querySelector('.ui-table-scrollable-body');
        const header: HTMLElement = table.el.nativeElement.querySelector('.ui-table-scrollable-header');
        body.addEventListener('scroll', () => {
          header.scrollLeft = body.scrollLeft;
        });
    }
}
