import { PersonLicenceType, PsyRegEducationDto, PsyRegPersonDto, SearchPsyRegPersonOutput } from './../../../shared/service-proxies/service-proxies';
import { DatePipe } from '@angular/common';
import { Injectable, Injector } from '@angular/core';
import {
    GetPsyRegPersonDetailedOutput,
    PsyRegPersonDetailedDto,
    GetPsyRegPersonOutput,
    PsyRegAuthorisationDto,
    PsyRegServiceProxy,
    SearchPsyRegPersonInput,
} from '@shared/service-proxies/service-proxies';
import { tap, delay, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AppConsts } from '@shared/AppConsts';
import { HealthRegisterBaseService } from './health-register-base.service';
import { DateTime } from 'luxon';
import { HealthRegisterPostgraduateTitle } from './dto/health-register-postgraduate-title';
import { HealthRegisterDiploma } from './dto/health-register-diploma';
import { HealthRegisterPostgraduateTitlePrivateLaw } from './dto/health-register-postgraduate-title-private-law';

@Injectable({
    providedIn: 'root',
})
export class PsyRegService extends HealthRegisterBaseService {

    latestPsyRegPerson: PsyRegPersonDetailedDto;

    constructor(
        protected datePipe: DatePipe,
        injector: Injector,
        private psyRegService: PsyRegServiceProxy,
    ) {
        super(datePipe, injector);
    }

    search(input: SearchPsyRegPersonInput): Observable<SearchPsyRegPersonOutput> {
        return this.psyRegService.search(input);
    }

    getPerson(registerPersonId: number): Observable<PsyRegPersonDto | string> {
        if (this.latestPsyRegPerson !== undefined && this.latestPsyRegPerson.id === registerPersonId) {
            return of(this.latestPsyRegPerson).pipe(delay(1));
        }

        return this.psyRegService.get(registerPersonId)
            .pipe(map((output: GetPsyRegPersonOutput) => (output.psyRegPerson)))
            .pipe(tap((output: PsyRegPersonDetailedDto) => (this.latestPsyRegPerson = output))); // Cheating mapping PsyRegPersonDto to PsyRegPersonDetailedDto
    }

    getPersonByGln(gln: string): Observable<PsyRegPersonDto | string> {
        // Try get from cache
        if (!!this.latestPsyRegPerson && this.latestPsyRegPerson.gln === gln) {
            return of(this.latestPsyRegPerson).pipe(delay(1));
        }

        // Get from server
        return this.psyRegService.getByGln(gln)
            .pipe(map((output: GetPsyRegPersonOutput) => (output.psyRegPerson)))
            .pipe(tap((output: PsyRegPersonDetailedDto) => (this.latestPsyRegPerson = output))); // Cheating mapping PsyRegPersonDto to PsyRegPersonDetailedDto
    }

    getPersonDetailed(registerPersonId: number): Observable<PsyRegPersonDetailedDto | string> {
        if (this.latestPsyRegPerson !== undefined && this.latestPsyRegPerson.id === registerPersonId) {
            return of(this.latestPsyRegPerson).pipe(delay(1));
        }

        return this.psyRegService.getDetailed(registerPersonId)
            .pipe(map((output: GetPsyRegPersonDetailedOutput) => (this.latestPsyRegPerson = output.psyRegPerson)))
            .pipe(tap((output: PsyRegPersonDetailedDto) => (this.latestPsyRegPerson = output)));
    }

    getPersonDetailedByGln(gln: string): Observable<PsyRegPersonDetailedDto> {

        // Try get from cache
        if (!!this.latestPsyRegPerson && this.latestPsyRegPerson.gln === gln) {
            return of(this.latestPsyRegPerson).pipe(delay(1));
        }

        // Get from server
        return this.psyRegService.getDetailedByGln(gln)
            .pipe(map((output: GetPsyRegPersonDetailedOutput) => (this.latestPsyRegPerson = output.psyRegPerson)))
            .pipe(tap((output: PsyRegPersonDetailedDto) => (this.latestPsyRegPerson = output)));
    }

    get SearchSuccessfulMessage(): string {
        return this.l('SearchSuccessfulPsyRegMessage');
    }

    get SearchFailedMessageKey(): string {
        return 'SearchFailedPsyRegMessage';
    }

    personWasFound(): boolean {
        return !!this.latestPsyRegPerson;
    }

    get fullName(): string {
        var person = this.latestPsyRegPerson;
        var name = person?.firstName;

        if (person?.name) {
            if (name) {
                name = name + ' ' + person.name;
            } else {
                name = person.name;
            }
        }

        return name;
    }

    get birthYear(): string {
        return this.latestPsyRegPerson?.birthYear;
    }

    get nationality1(): string {
        return this.latestPsyRegPerson?.nationality1.sironaId;
    }

    get nationality2(): string {
        return this.latestPsyRegPerson?.nationality2?.sironaId;
    }

    get queryTime(): DateTime {
        return this.latestPsyRegPerson?.queryTime;
    }

    get ahv(): string {
        return null;
    }

    get gender(): string {
        return this.latestPsyRegPerson?.gender.sironaId;
    }

    get gln(): string {
        return this.latestPsyRegPerson?.gln;
    }

    get firstName(): string {
        return this.latestPsyRegPerson?.firstName;
    }

    get lastName(): string {
        return this.latestPsyRegPerson?.name;
    }

    get birthName(): string {
        return null;
    }

    get placeOfOrigin(): string {
        return null;
    }

    get languageSkills(): string[] {
        if (this.latestPsyRegPerson == null) return [];
        return this.latestPsyRegPerson.languageSkills.filter(x => x.sironaId !== null).map(x => x.sironaId);
    }

    get languageSkillsAsCommaSeparatedList(): string {
        let skills = this.languageSkills;
        return skills?.length > 0 ? skills.map(s => this.localization.localize(s, this.lSource)).join(", ") : null;
    }

    get diplomas(): string[] {
        let list: string[] = [];
        let diploma = this.latestPsyRegPerson?.psyRegEducations?.filter(x => x.isPostgraduateTitle == false);
        if (diploma) {
            for (let i = 0; i < diploma.length; i++) {
                list.push(
                    `${this.localization.localize(diploma[i].profession.sironaId, this.lSource)}, ${this.resolveDate(diploma[i].issuingDate)}`
                );
            }
        }
        return list;
    }

    //private displayCorrectDiplomaDate(diploma: PsyRegEducationDto): string {
    //    if (diploma.typeId == AppConsts.codes.diploma.psyreg.type.federalPsyReg) {
    //        return this.resolveDate(diploma.issuingDate);
    //    }

    //    return this.resolveDate(diploma.recognitionDate);
    //}

    getProfessionDiplomaForComparison(professionId: string): HealthRegisterDiploma {

        let diplomas = this.latestPsyRegPerson?.psyRegEducations?.filter(x => x.isPostgraduateTitle == false);
        if (diplomas) {
            const diploma = diplomas.find(x => x.profession.sironaId === professionId);
            if (diploma == null) {
                return null;
            } else {
                const healthRegisterDiploma = new HealthRegisterDiploma();
                healthRegisterDiploma.professionId = diploma.profession.sironaId;
                healthRegisterDiploma.typeId = diploma.typeId;
                healthRegisterDiploma.countryId = diploma.issuingCountry.sironaId;
                healthRegisterDiploma.dateOfIssue = this.resolveDate(diploma.issuingDate);
                healthRegisterDiploma.dateOfRecognition = this.resolveDate(diploma.recognitionDate);
                healthRegisterDiploma.placeOfIssue = diploma.issuingPlace;
                return healthRegisterDiploma;
            }
        }
    }

    getLicences(): PsyRegAuthorisationDto[] {
        return this.latestPsyRegPerson?.psyRegAuthorisations;
    }

    get normalLicences(): string[] {
        const list = [];
        let licences = this.getLicences()?.filter((l) => l.licenceType !== PersonLicenceType.ProfessionalNinetyDays && l.licenceType !== PersonLicenceType.ExpenceHealthCareNinetyDays);
        if (licences == null) return list;
        for (let i = 0; i < licences.length; i++) {
            list.push(
                `${this.localization.localize(licences[i].diplomaType?.healthRegisterDescription, this.lSource)},
                 ${this.localization.localize(licences[i].canton.sironaId, this.lSource)},
                 ${this.resolveDate(licences[i].decisionDate)},
                 ${this.localization.localize(licences[i].licenceStatus?.healthRegisterDescription, this.lSource)}`
            );
        }
        return list;
    }

    get dl90Licences(): string[] {
        const list = [];
        let licences = this.getLicences()?.filter(
            (l) => l.licenceType === PersonLicenceType.ProfessionalNinetyDays || l.licenceType === PersonLicenceType.ExpenceHealthCareNinetyDays
        );
        if (licences == null) return list;
        for (let i = 0; i < licences.length; i++) {
            list.push(
                `${this.localization.localize(licences[i].diplomaType.sironaId, this.lSource)},
                 ${this.localization.localize(licences[i].canton.sironaId, this.lSource)},
                 ${this.resolveDate(licences[i].decisionDate)},
                 ${this.localization.localize(licences[i].licenceStatus?.healthRegisterDescription, this.lSource)}`
            );
        }
        return list;
    }

    get hasSensitiveData(): boolean {
        const dataDeservingProtection = this.latestPsyRegPerson.dataDeservingProtection;
        return dataDeservingProtection == true;
    }

    get hasSensitiveDataCantonalLaw(): boolean {
        return false;
    }

    get cantonalLicenceRestrictions(): string[] {
        return this.latestPsyRegPerson?.cantonalLicenceRestrictions;
    }

    getLanguage(psyRegId: number): Promise<string> {
        return new Promise((resolve) => {
            this.getPerson(psyRegId).subscribe((output: PsyRegPersonDetailedDto) => {
                let language;
                const languageOfCorrespondence = output.languageOfCorrespondence.sironaId;
                if (
                    languageOfCorrespondence == AppConsts.codes.language.german ||
                    languageOfCorrespondence == AppConsts.codes.language.french
                ) {
                    language = languageOfCorrespondence;
                }
                resolve(language);
            });
        });
    }

    get birthDate(): string {
        return this.resolveDate(this.latestPsyRegPerson?.birthdate);
    }

    get postgraduateTitles(): string[] {
        let list: string[] = [];
        let titles = this.latestPsyRegPerson?.psyRegEducations.filter(x => x.isPostgraduateTitle == true);
        if (titles) {
            for (let i = 0; i < titles.length; i++) {
                list.push(
                    this.localization.localize(titles[i].typeId, this.lSource) +
                    ' ' +
                    this.resolveLanguage(titles[i].postgraduateKindLabel) +
                    ', ' +
                    this.resolveDate(titles[i].issuingDate)
                );
            }
        }
        return list;
    }

    getPostgraduateTitlesForPerson(registerPersonId: number): Observable<string[] | string> {
        return this.getPerson(registerPersonId).pipe(map((output: PsyRegPersonDetailedDto) => {
            return output.psyRegEducations.filter(x => x.isPostgraduateTitle == true).map((wbt: PsyRegEducationDto) => wbt.typeId);
        }));
    }

    private displayCorrectPostgraduateTitleDate(postgraduateTitle: PsyRegEducationDto): string {
        if (postgraduateTitle.typeId === AppConsts.codes.psyreg.postgraduateTitle.type.federal) {
                return this.resolveDate(postgraduateTitle.issuingDate);
            }

        return this.resolveDate(postgraduateTitle.recognitionDate);
    }

    getPostgraduateTitlesForComparison(): HealthRegisterPostgraduateTitle[] {
        let titles = this.latestPsyRegPerson?.psyRegEducations.filter(x => x.isPostgraduateTitle == true);
        if (titles) {
            const healthRegisterPostgraduateTitles: HealthRegisterPostgraduateTitle[] = [];
            for (let i = 0; i < titles.length; i++) {
                const healthRegisterPostgraduateTitle = new HealthRegisterPostgraduateTitle();
                healthRegisterPostgraduateTitle.titleId = titles[i].kind.sironaId;
                healthRegisterPostgraduateTitle.typeId = titles[i].typeId;
                healthRegisterPostgraduateTitle.dateOfIssue = this.resolveDate(titles[i].issuingDate);
                healthRegisterPostgraduateTitle.dateOfRecognition = this.resolveDate(titles[i].recognitionDate);
                healthRegisterPostgraduateTitle.placeOfIssue = titles[i].issuingPlace;
                healthRegisterPostgraduateTitle.countryId = titles[i].issuingCountry.sironaId;
                healthRegisterPostgraduateTitles.push(healthRegisterPostgraduateTitle);
            }
            return healthRegisterPostgraduateTitles;
        }
    }

    getPostgraduateTitlesPrivateLawForComparison(): HealthRegisterPostgraduateTitlePrivateLaw[] {
        return [];
    }
}
