import { EhcDto, EhcServiceProxy, EhcStatus, GetEhcOutput, EhcSelfEmploymentServiceProxy, CreateOrUpdateEhcSelfEmploymentInput, EhcSelfEmploymentDto, EhcMedicalFieldDto, CreateOrUpdateEhcMedicalFieldInput, EhcSearchDto, EhcMedicalFieldServiceProxy, MedRegWbtDto, EhcLanguageSkillServiceProxy, EhcPracticeServiceProxy, EhcElectronicPatientRecordServiceProxy, EhcContactServiceProxy, EhcInvoiceAddressServiceProxy, EhcLanguageSkillDto, CreateOrUpdateEhcLanguageSkillInput, EhcDocumentsOutput, EhcPracticeDto, CreateOrUpdateEhcPracticesInput, CreateOrUpdateEhcPracticesOutput, EhcElectronicPatientRecordDto, CreateOrUpdateEhcElectronicPatientRecordInput, EhcQualityMeasurementServiceProxy, EhcQualityMeasurementDto, CreateOrUpdateEhcQualityMeasurementInput, EhcContactDto, CreateOrUpdateEhcContactInput, CreateOrUpdateEhcEmploymentsInput, EhcPostgraduateTitleDto, EhcEmploymentOutput, EhcInvoiceAddressDto, EhcEmploymentDto, EhcPersonServiceProxy, EhcPersonDto, CreateOrUpdateEhcPersonInput, LiaTypeServiceProxy, ExternalSystemEnum, EhcDocumentServiceProxy, EhcStaffDto, CreateOrUpdateEhcStaffInput, EhcQualitySupportSystemServiceProxy, EhcQualitySupportSystemDto, CreateOrUpdateEhcQualitySupportSystemInput, CreateOrUpdateEhcPostgraduateTitlesInput, EhcPostgraduateTitleServiceProxy, CreateOrUpdateEhcPostgraduateTitlesOutput, CreateOrUpdateEhcPostgraduateTitlePracticeInput, GetEhcPersonalLicenceDto, CreateOrUpdateEhcInvoiceAddressInput } from './../../../shared/service-proxies/service-proxies';
import { Injectable, Injector } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { tap, map, delay, switchMap } from 'rxjs/operators';
import {
    ApplicationUploadFilesInput,
    ApplicationServiceProxy,
    GeneralFileInfoDto,
    GeneralFileDto,
    EhcStaffServiceProxy,
    GeneralDocumentGroupDto, LiaContactDto
} from '@shared/service-proxies/service-proxies';
import { SelectItem } from 'primeng/api';
import { AppConsts } from '@shared/AppConsts';
import { ApplicationService } from
    '@app/shared/application/application.service';
import { CodeService } from '@app/shared/common/code/code.service';

@Injectable({
    providedIn: 'root',
})
export class EhcService extends ApplicationService {

    ehc: EhcDto;
    ehcResultDocuments: GeneralFileInfoDto[];

    constructor(
        injector: Injector,
        private appService: ApplicationServiceProxy,
        codeService: CodeService,
        private ehcService: EhcServiceProxy,
        private ehcSelfEmploymentService: EhcSelfEmploymentServiceProxy,
        private ehcMedicalFieldService: EhcMedicalFieldServiceProxy,
        private ehcLanguageSkillService: EhcLanguageSkillServiceProxy,
        private ehcPracticeService: EhcPracticeServiceProxy,
        private ehcElectronicPatientRecordService: EhcElectronicPatientRecordServiceProxy,
        private ehcQualityMeasurementService: EhcQualityMeasurementServiceProxy,
        private ehcStaffService: EhcStaffServiceProxy,
        private ehcQualitySupportSystemService: EhcQualitySupportSystemServiceProxy,
        private ehcPersonService:  EhcPersonServiceProxy,
        private ehcPostgraduateTitleService: EhcPostgraduateTitleServiceProxy,
        private ehcDocumentService:  EhcDocumentServiceProxy,
        private ehcContactService:  EhcContactServiceProxy,
        private ehcInvoiceAddressService: EhcInvoiceAddressServiceProxy,
        private liaTypeService: LiaTypeServiceProxy,
    ) {
        super(injector, codeService, appService);
    }

    getExternalSystemByProfessionId(professionId: string): Observable<ExternalSystemEnum> {
        return this.liaTypeService.getExternalRegisterByProfessionId(professionId);
    }

    getEhc(caseId: string): Observable<GetEhcOutput> {
        this.ehc = null;
        return this.getApplication(caseId).pipe(
            switchMap(() => this.ehcService.getEhc(caseId).pipe(
                    tap((output: GetEhcOutput) => {
                        this.ehc = output.ehc;
                    })
                ))
        );
    }

    getMyEhcs(): Observable<EhcDto[]> {
        return this.ehcService.getMyEhcs();
    }

    getEhcsForPerson(gln: string): Observable<EhcDto[]> {
        return this.ehcService.getEhcsForPerson(gln);
    }

    getEhcPersonalLicenceForEhc(): Observable<GetEhcPersonalLicenceDto> {
        return this.ehcService.getExpenseHealthCarePersonalLicenceForEhc(this._caseId);
    }

    get caseId(): string {
        return this.ehc.application.caseId;
    }

    get profession(): string {
        return this.ehc.professionId;
    }

    get liaCaseId(): string {
        return this.ehc.liaCaseId;
    }

    checkDocuments(): Observable<EhcStatus[]> {
        return this.ehcService.validateThatAllMandatoryDocumentsHaveBeenUploaded(this.caseId);
    }

    checkEhcPracticeDocuments() {
        const documents = this.ehc.ehcPractice.ehcPracticeRecordDocuments;
        if (documents == null) return false;
        const practiceRecordCount = this.ehc.ehcPractice.ehcPracticeRecords.length;
        const fileGroup = documents.filter((document) => document.typeId === AppConsts.codes.documentType.proofOfPractice);
        const proofOfPracticeDocuments = fileGroup[0].files;
        return proofOfPracticeDocuments.length >= practiceRecordCount;
    }

    getEvaluationComments(): Observable<string> {
        return this.ehcService.getEvaluationComments(this._caseId);
    }

    getNextStatus(status: EhcStatus): Observable<EhcStatus> {
        return this.ehcService.getNextStatus(this._caseId, status);
    }

    getSelfEmployment(): Observable<EhcSelfEmploymentDto> {
        return of(this.ehc.ehcSelfEmployment).pipe(delay(0));
    }

    getPostgraduateTitles(): Observable<SelectItem[]> {
        return this.ehcMedicalFieldService.getPostgraduateTitles(this.caseId).pipe(
          map((items: MedRegWbtDto[]) => {
            return items.map((item: MedRegWbtDto) => {
              return {
                label: item.art.sironaId,
                value: item.art.sironaId
              };
            });
          })
        );
    }

    createOrUpdateEhcSelfEmployment(input: CreateOrUpdateEhcSelfEmploymentInput): Observable<void> {
        this.setStepToReview(EhcStatus.SelfEmployment);
        return this.ehcSelfEmploymentService.createOrUpdateEhcSelfEmployment(input).pipe(tap((status: EhcStatus) => {
            this.ehc.ehcSelfEmployment = input.ehcSelfEmployment;
            this.ehc.currentStep = status;
        })).pipe(map((status: EhcStatus) => null));
    }

    getEhcMedicalField(): Observable<EhcMedicalFieldDto>
    {
        return of(this.ehc.ehcMedicalField).pipe(delay(0));
    }

    createOrUpdateEhcMedicalField(input: CreateOrUpdateEhcMedicalFieldInput): Observable<void> {
        this.setStepToReview(EhcStatus.MedicalField);
        return this.ehcMedicalFieldService.createOrUpdateEhcMedicalField(input).pipe(tap((status: EhcStatus) => {
            this.ehc.ehcMedicalField = input.ehcMedicalField;
            this.ehc.currentStep = status;
        })).pipe(map((status: EhcStatus) => null));
    }

    getEhcPostgraduateTitles(): Observable<EhcPostgraduateTitleDto[]>
    {
        return this.ehcPostgraduateTitleService.getList(this.caseId);
    }

    createOrUpdateEhcPostgraduateTitles(input: CreateOrUpdateEhcPostgraduateTitlesInput): Observable<void> {
        this.setStepToReview(EhcStatus.PostgraduateTitle);
        return this.ehcPostgraduateTitleService.createOrUpdateEhcPostgraduateTitles(input).pipe(tap((output: CreateOrUpdateEhcPostgraduateTitlesOutput) => {
            this.ehc.ehcPostgraduateTitles = output.ehcPostgraduateTitles;
            this.ehc.currentStep = output.status;
        })).pipe(map((output: CreateOrUpdateEhcPostgraduateTitlesOutput) => null));
    }

    createOrUpdateEhcPostgraduateTitlePractices(input: CreateOrUpdateEhcPostgraduateTitlePracticeInput): Observable<void> {
        this.setStepToReview(EhcStatus.Practice);
        return this.ehcPostgraduateTitleService.createOrUpdateEhcPostgraduateTitlePractices(input).pipe(tap((output: CreateOrUpdateEhcPostgraduateTitlesOutput) => {
            this.ehc.ehcPostgraduateTitles = output.ehcPostgraduateTitles;
            this.ehc.currentStep = output.status;
        })).pipe(map((output: CreateOrUpdateEhcPostgraduateTitlesOutput) => null));
    }

    getEhcLanguageSkill(): Observable<EhcLanguageSkillDto> {
        return of(this.ehc.ehcLanguageSkill).pipe(delay(0));
    }

    createOrUpdateEhcLanguageSkill(input: CreateOrUpdateEhcLanguageSkillInput): Observable<void> {
        this.setStepToReview(EhcStatus.LanguageSkill);
        return this.ehcLanguageSkillService.createOrUpdateEhcLanguageSkill(input).pipe(tap((status: EhcStatus) => {
            this.ehc.ehcLanguageSkill = input.ehcLanguageSkill;
            this.ehc.currentStep = status;
        })).pipe(map((status: EhcStatus) => null));
    }

    getEhcPractice(): Observable<EhcPracticeDto> {
        return this.ehcPracticeService.getEhcPractice(this.caseId);
    }

    createBlankEhcPracticeRecord(): Observable<string> {
        return this.ehcPracticeService.createBlankEhcPracticeRecord(this.caseId);
    }

    createOrUpdateEhcPractices(input: CreateOrUpdateEhcPracticesInput): Observable<void> {
        this.setStepToReview(EhcStatus.Practice);
        return this.ehcPracticeService.createOrUpdateEhcPractices(input).pipe(tap((output: CreateOrUpdateEhcPracticesOutput) => {
            this.ehc.ehcPractice = input.ehcPractice;
            this.ehc.ehcPractice.ehcPracticeConfigSnapshot = output.ehcPractice.ehcPracticeConfigSnapshot;
            this.ehc.currentStep = output.status;
        })).pipe(map((output: CreateOrUpdateEhcPracticesOutput) => null));
    }

    deleteEhcPracticeRecord(guid: string): Observable<void> {
        return this.ehcPracticeService.deleteEhcPracticeRecord(this.caseId, guid);
    }

    getEhcPracticeRecordFiles(forceRefresh?: boolean, caseId = this._caseId) : Observable<GeneralDocumentGroupDto[]> {
        if (forceRefresh) {
            return this.ehcPracticeService.getEhcPracticeRecordFiles(caseId, EhcStatus.Practice).pipe(tap((output: EhcDocumentsOutput) => {
                this.ehc.ehcPractice.ehcPracticeRecordDocuments = output.ehcDocuments;
            })).pipe(map((output: EhcDocumentsOutput) => output.ehcDocuments));
        }
        return of(this.ehc.ehcPractice.ehcPracticeRecordDocuments);
    }

    getEhcPracticeRecordFile(fileId: number): Observable<GeneralFileDto> {
        return this.ehcPracticeService.getFile(this._caseId, EhcStatus.Practice, fileId)
    }

    uploadEhcPracticeRecordFiles(ehcPracticeRecordId: string, input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.ehcPracticeService.uploadFiles(ehcPracticeRecordId, input);
    }

    deleteEhcPracticeRecordFile(fileId: number): Observable<void> {
        return this.ehcPracticeService.deleteFile(this._caseId, EhcStatus.Practice, fileId);
    }

    getEhcElectronicPatientRecord(): Observable<EhcElectronicPatientRecordDto> {
        return of(this.ehc.ehcElectronicPatientRecord).pipe(delay(0));
    }

    createOrUpdateEhcElectronicPatientRecord(input: CreateOrUpdateEhcElectronicPatientRecordInput): Observable<void> {
        this.setStepToReview(EhcStatus.ElectronicPatientRecord);
        return this.ehcElectronicPatientRecordService.createOrUpdateEhcElectronicPatientRecord(input).pipe(tap((status: EhcStatus) => {
            this.ehc.ehcElectronicPatientRecord = input.ehcElectronicPatientRecord;
            this.ehc.currentStep = status;
        })).pipe(map((status: EhcStatus) => null));
    }

    getEhcQualityMeasurement(): Observable<EhcQualityMeasurementDto> {
        return of(this.ehc.ehcQualityMeasurement).pipe(delay(0));
    }

    createOrUpdateEhcQualityMeasurement(input: CreateOrUpdateEhcQualityMeasurementInput): Observable<void> {
        this.setStepToReview(EhcStatus.QualityMeasurement);
        return this.ehcQualityMeasurementService.createOrUpdateEhcQualityMeasurement(input).pipe(tap((status: EhcStatus) => {
            this.ehc.ehcQualityMeasurement = input.ehcQualityMeasurement;
            this.ehc.currentStep = status;
        })).pipe(map((status: EhcStatus) => null));
    }

    getEhcStaff(): Observable<EhcStaffDto> {
        return of(this.ehc.ehcStaff).pipe(delay(0));
    }

    createOrUpdateEhcStaff(input: CreateOrUpdateEhcStaffInput): Observable<void> {
        this.setStepToReview(EhcStatus.Staff);
        return this.ehcStaffService.createOrUpdateEhcStaff(input).pipe(tap((status: EhcStatus) => {
            this.ehc.ehcStaff = input.ehcStaff;
            this.ehc.currentStep = status;
        })).pipe(map((status: EhcStatus) => null));
    }

    getEhcQualitySupportSystem(): Observable<EhcQualitySupportSystemDto> {
        return of(this.ehc.ehcQualitySupportSystem).pipe(delay(0));
    }

    createOrUpdateEhcQualitySupportSystem(input: CreateOrUpdateEhcQualitySupportSystemInput): Observable<void> {
        this.setStepToReview(EhcStatus.QualitySupportSystem);
        return this.ehcQualitySupportSystemService.createOrUpdateEhcQualitySupportSystem(input).pipe(tap((status: EhcStatus) => {
            this.ehc.ehcQualitySupportSystem = input.ehcQualitySupportSystem;
            this.ehc.currentStep = status;
        })).pipe(map((status: EhcStatus) => null));
    }


    getEhcPerson(): Observable<EhcPersonDto> {
        return of(this.ehc.ehcPerson).pipe(delay(0));
    }

    createOrUpdateEhcPerson(input: CreateOrUpdateEhcPersonInput): Observable<void> {
        this.setStepToReview(EhcStatus.Person);
        return this.ehcPersonService.createOrUpdateEhcPerson(input).pipe(tap((status: EhcStatus) => {
            this.ehc.ehcPerson = input.ehcPerson;
            this.ehc.currentStep = status;
        })).pipe(map((status: EhcStatus) => null));
    }

    getEhcFiles(step: EhcStatus, caseId = this._caseId): Observable<GeneralDocumentGroupDto[]> {
        return this.ehcDocumentService.getEhcDocuments(caseId, step).pipe(map((output: EhcDocumentsOutput) => output.ehcDocuments));
    }

    getEhcFile(step: EhcStatus, fileId: number): Observable<GeneralFileDto> {
        return this.ehcDocumentService.getFile(this._caseId, step, fileId);
    }

    uploadEhcFiles(step: EhcStatus, input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        input.step = step;
        return this.ehcDocumentService.uploadFiles(input);
    }

    deleteEhcFile(step: EhcStatus, fileId: number): Observable<void> {
        return this.ehcDocumentService.deleteFile(this._caseId, step, fileId);
    }

    getEhcContact(): Observable<EhcContactDto> {
        return of(this.ehc.ehcContact).pipe(delay(0));
    }

    createOrUpdateEhcContact(input: CreateOrUpdateEhcContactInput): Observable<void> {
        this.setStepToReview(EhcStatus.Contact);
        return this.ehcContactService.createOrUpdateEhcContact(input).pipe(tap((status: EhcStatus) => {
            this.ehc.ehcContact = input.ehcContact;
            this.ehc.currentStep = status;
        })).pipe(map((status: EhcStatus) => null));
    }

    proceedEhcContact(): Observable<void> {
        return this.ehcContactService.proceedEhcContact(this.caseId).pipe(tap((status: EhcStatus) => {
            this.ehc.currentStep = status;
        })).pipe(map((status: EhcStatus) => null));
    }

    getEmployments(): Observable<EhcEmploymentOutput> {
        return of(this.ehc.ehcEmployments).pipe(delay(0));
    }

    createOrUpdateEhcEmployments(input: CreateOrUpdateEhcEmploymentsInput): Observable<void> {
        this.setStepToReview(EhcStatus.Employment);
        return this.ehcPostgraduateTitleService.createOrUpdateEhcEmployments(input).pipe(tap((output: CreateOrUpdateEhcPostgraduateTitlesOutput) => {
            this.ehc.ehcPostgraduateTitles = output.ehcPostgraduateTitles;
            this.ehc.currentStep = output.status;
        })).pipe(map((output: CreateOrUpdateEhcPostgraduateTitlesOutput) => null));
    }

    createPractice(titleId: number): Observable<string> {
        return this.ehcPostgraduateTitleService.createBlankPractice(this._caseId, titleId);
    }

    deletePractice(practiceId: string): Observable<void> {
        return this.ehcPostgraduateTitleService.deletePractice(this._caseId, practiceId);
    }

    getEhcPostgraduateTitlePracticeFiles(practice: string, caseId = this._caseId): Observable<GeneralDocumentGroupDto[]> {
        return this.ehcPostgraduateTitleService.getPracticeFiles(caseId, practice).pipe(map((output: EhcDocumentsOutput) => output.ehcDocuments));
    }

    getEhcPostgraduateTitlePracticeFile(fileId: number): Observable<GeneralFileDto> {
        return this.ehcPostgraduateTitleService.getFile(this._caseId, EhcStatus.Practice, fileId)
    }

    uploadEhcPostgraduateTitlePracticeFiles(practiceId: string, input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.ehcPostgraduateTitleService.uploadPracticeFiles(practiceId, input);
    }

    deleteEhcPostgraduateTitlePracticeFile(fileId: number): Observable<void> {
        return this.ehcPostgraduateTitleService.deleteFile(this._caseId, EhcStatus.Practice, fileId);
    }

    getLiaContactReadOnly(caseId: string): Observable<LiaContactDto> {
        return this.ehcContactService.getLiaContactReadOnly(caseId);
    }

    hasEmployment(): boolean {
        let hasEmployment = false;
        for (let j = 0; j < this.ehc.ehcPostgraduateTitles?.length; j++) {
            hasEmployment = hasEmployment || this.ehc.ehcPostgraduateTitles[j].ehcEmployment?.length > 0;
        }
        return hasEmployment;
    }

    getEhcInvoiceAddress(): Observable<EhcInvoiceAddressDto> {
        return of(this.ehc.ehcInvoiceAddress).pipe(delay(0));
    }

    ehcInvoiceAddressOk(): Observable<void> {
        this.setStepToReview(EhcStatus.InvoiceAddress);
        return this.ehcInvoiceAddressService.ehcInvoiceAddressOk(this.caseId).pipe(tap((status: EhcStatus) => {
            this.ehc.currentStep = status;
        })).pipe(map((status: EhcStatus) => null));
    }

    getInvoiceAddressDetail(): Observable<EhcInvoiceAddressDetail> {
        let fullName = this.ehc.ehcContact.titleId !== null ? this.l(this.ehc.ehcContact.titleId) + ' '
        + this.ehc.ehcPerson.firstName + ' ' + this.ehc.ehcPerson.lastName : this.ehc.application.name;
        let detail;
        if (this.ehc.ehcContact.countryId === AppConsts.codes.country.ch) {
            detail = new EhcInvoiceAddressDetail({
                name: fullName,
                street: this.ehc.ehcContact.street,
                streetNr: this.ehc.ehcContact.streetNr,
                postCode: this.ehc.ehcContact.postCode,
                city: this.ehc.ehcContact.city,
                email: this.ehc.ehcContact.email,
                countryId: this.ehc.ehcContact.countryId,
                postOfficeBox: this.ehc.ehcContact.postOfficeBox,
            });
        } else {
            detail = new EhcInvoiceAddressDetail({
                name: this.ehc.ehcContact.titleAddressSwiss,
                street: this.ehc.ehcContact.streetSwiss,
                streetNr: this.ehc.ehcContact.streetNrSwiss,
                postCode: this.ehc.ehcContact.postCodeSwiss,
                city: this.ehc.ehcContact.citySwiss,
                email: this.ehc.ehcContact.email,
                countryId: AppConsts.codes.country.ch,
                postOfficeBox: this.ehc.ehcContact.postOfficeBoxSwiss,
            });
        }
        return of(detail).pipe(delay(0));
    }

    createAddressDropdownList(): SelectItem[] {

        var addresses: SelectItem[] = [];

        const allNull = Object.values(this.ehc.ehcContact).every(value =>  {
        if (value === null) {
            return true;
        }
        });

        if (!allNull) {
            addresses.push({ value: AppConsts.codes.address.private, label: this.l('Address-Private') + ': ' + this.getPrivateAddressDescription(this.ehc.ehcContact) });
        }

        for (let j = 0; j < this.ehc.ehcPostgraduateTitles?.length; j++) {
            for (let i = 0; i < this.ehc.ehcPostgraduateTitles[j].ehcEmployment?.length; i++) {
                // Use employment id as item value
                addresses.push({ value: this.ehc.ehcPostgraduateTitles[j].ehcEmployment[i].id, label: this.l('EmploymentSingular') + ': ' + this.getEmploymentAddressDescription(this.ehc.ehcPostgraduateTitles[j].ehcEmployment[i]) });
            }
        }

        if (addresses.length > 0) {
            addresses.push({ value: AppConsts.codes.address.invoice, label: this.l(AppConsts.codes.address.invoice) });
        }

        return addresses;
    }

    private getPrivateAddressDescription(contact: EhcContactDto) {
        let str = contact?.street;

        if (contact?.streetNr && contact.streetNr.length > 0) {
            str = str.concat(' ');
            str = str.concat(contact.streetNr);
        }

        str = str?.concat(', ');
        str = str?.concat(contact?.city);

        return str;
    }

    private getEmploymentAddressDescription(employment: EhcEmploymentDto) {
        let str = employment?.businessName;

        if (employment.street?.length > 0) {
            if (str.length > 0) {
                str = str.concat(', ');
            }

            str = str.concat(employment.street);

            if (employment.streetNr?.length > 0) {
                str = str.concat(' ');
                str = str.concat(employment.streetNr);
            }
        }

        if (employment.city?.length > 0) {
            if (str.length > 0) {
                str = str.concat(', ');
            }

            str = str.concat(employment.city);
        }

        return str;
    }

    createOrUpdateEhcInvoiceAddress(input: CreateOrUpdateEhcInvoiceAddressInput): Observable<void> {
        this.setStepToReview(EhcStatus.InvoiceAddress);
        return this.ehcInvoiceAddressService.createOrUpdateEhcInvoiceAddress(input).pipe(tap((status: EhcStatus) => {
            this.ehc.ehcInvoiceAddress = input.ehcInvoiceAddress;
            this.ehc.currentStep = status;
        }))
            .pipe(map((status: EhcStatus) => null));
    }

    releaseEhc(comment: string): Observable<void> {

        if (comment === null) {
            comment = undefined;
        }

        return this.ehcService.releaseEhc(this._caseId, comment);
    }

    getStepUrl(nextStep: EhcStatus): string {
        return this.getNextStep(this.getUrlForStep(nextStep));
    }

    isInEhcStatus(expectedStatus: EhcStatus): boolean {
        return this.ehc.currentStep === expectedStatus;
    }

    withdrawEhc(): Observable<void> {
        return this.ehcService.withdrawEhc(this._caseId);
    }

    getUrlForStep(step: number): string {
        switch (step) {
            case EhcStatus.SelfEmployment:
                return 'self-employment';
            case EhcStatus.Person:
                return 'person';
            case EhcStatus.Register:
                return 'register';
            case EhcStatus.MedicalField:
                return 'medical-field';
            case EhcStatus.LanguageSkill:
                return 'language-skills';
            case EhcStatus.PostgraduateTitle:
                return 'postgraduate-title';
            case EhcStatus.Practice:
                return 'practice';
            case EhcStatus.ElectronicPatientRecord:
                return 'electronic-patient-record';
            case EhcStatus.QualityMeasurement:
                return 'quality-measurement';
            case EhcStatus.Staff:
                return 'staff';
            case EhcStatus.QualitySupportSystem:
                return 'quality-support-system';
            case EhcStatus.Contact:
                return 'contact';
            case EhcStatus.Employment:
                return 'employment';
            case EhcStatus.InvoiceAddress:
                return 'invoice-address';
            case EhcStatus.Release:
                return 'release';
            case EhcStatus.FinishReview:
                return 'finish-review';
            default:
                console.error('Invalid status for getUrlForStep');
                return null;
        }
    }

}

export class EhcInvoiceAddressDetail {
    name: string;
    street: string;
    streetNr: string;
    postCode: string;
    city: string;
    email: string;
    countryId: string;
    postOfficeBox: string;

    constructor (data: EhcInvoiceAddressDetail) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }
}
